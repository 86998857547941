import { rootReducer } from '@/state/index'
import { BaseThunkAPI } from '@reduxjs/toolkit/src/createAsyncThunk'
import { EnrollmentState } from '@/state/enrollment'
import { PlanState } from '@/state/plan'
import { InvitationPlanState } from '@/state/invitationPlan'
import { VerificationState } from '@/state/verification'
import { Action, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { TrackingState } from '@/state/tracking'
import { QuickviewState } from './quickview'
import { RecaptchaState } from './recaptcha'
import { IndicativeEnrichmentState } from './indicativeEnrichment'
import { AddOnsState } from './addOns'
import { ActivationState } from './activation'
import { ResidentDataState } from './residentData'
import { DarkWebState } from './darkWeb'
import { MemberStatus as MembershipStatus } from '@/constants'

export type ThunkAPI = BaseThunkAPI<RootState, null, AppDispatch>

export enum IDQuestionsResponseCodes {
  Correct = 10002,
  MoreQuestions,
  Incorrect,
  AccountCodeMissing = 102006,
  Error = 'ERROR',
}

export enum EnrollmentStep {
  AccountInfo,
  IdentityInfo,
  BillingInfo,
}

export interface RootReducerState {
  enrollment: EnrollmentState
  plan: PlanState
  invitationPlan: InvitationPlanState
  verification: VerificationState
  tracking: TrackingState
  quickview: QuickviewState
  recaptcha: RecaptchaState
  indicativeEnrichment: IndicativeEnrichmentState
  addOns: AddOnsState
  activation: ActivationState
  residentData: ResidentDataState
  darkWeb: DarkWebState
}

export interface Plan {
  benefits: Record<string, Record<string, string>>
  orderedBenefitsList: string[]
  billingInterval: string
  billingIntervalShort: string
  displayName: string
  extraFee?: string
  planCode: string
  defaultPrice: string
  price: string
  trackingInfo: TrackingInfo
  freeDays?: string
  trialFee?: string
  oneTimeFee?: string
  nextBillDate?: string
  ignoreTrialNextBillDate?: string
  isQuickviewReportEnabled?: boolean
  isDisplayQuickView?: boolean
  phone: string
  partnerCode: string
  businessLogo?: string
  isFamilyPlan?: boolean
  coupon?: []
  isDisplayIdentityQuickView?: boolean
  isPlanAuthRequired?: boolean
  isPromptForDiscountCode?: boolean
  isEffortAuthRequired?: boolean
  isBypassReinstatement?: boolean
  enableKountService?: boolean
  addOnInterstitialPagePosition?: string
  proveMFAEnabled: boolean
  proveTrustScoreEnabled: boolean
  provePrefillEnabled: boolean
  proveTrustScoreMax: string
  proveTrustScoreMin: string
  proveSMSTemplate: string
  trustedFormEnabled: boolean
  pencilBannerEnabled: boolean
  proveByPassAuthTrustScore?: number
  effortEndDate?: string
  redirectEffort?: string
  error?: {
    reason: string
    code: string
  } | null
}

export interface Activation {
  effortCode: string
  planCode: string
  memberInfo: ActivationMemberInfo
}

export interface TrackingInfo {
  trackingUrls?: Record<string, string>
  trackingTrigger?: string
}

export enum MemberStatus {
  Active = 'active',
  Cancelled = 'cancelled',
}

export interface MemberAddress {
  city: string
  state: string
  street: string
  type?: 'Billing' | 'Shipping' | 'Previous'
  zip: string
}

export interface MemberPhone {
  type: 'Home' | 'Cell'
  number: string
}

export interface Coupon {
  couponCode: string
  couponLimit: string
  couponPrice: string
  couponUsage: string
  itemCode: string
  planCode: string
  planPrice: string
  isValid: boolean | null
}

export interface ProveInformation {
  lastFourSSN: string
  phone: string
}

export interface ProveAuthUrl {
  status: number
  authenticationUrl: string
  mobileOperatorName: string
  description: string
  additionalInfo: string
}

export interface TrustScore {
  status: number
  description: string
  additionalInfo: string
  trustScore: number
}

export interface ActivationMemberInfo {
  membershipNo: string
  firstName: string
  lastName: string
  email: string
  address: string
  city: string
  state: string
  zip: string
  dateOfBirth: string
  pnone: string
  ssn: string
  orderSource: string
}

export interface ResidentData {
  residentId: string
  firstName: string
  lastName: string
  email: string
  phone: string
  address: string
  city: string
  state: string
  zip: string
  dateOfBirth: string
  ssn: string
  memberStatus?: MembershipStatus
}

export interface Prefill {
  status: number
  description: string
  additionalInfo: string
  firstName: string
  lastName: string
  addresses: {
    address: string
    extendedAddress: string
    city: string
    region: string
    postalCode: string
  }[]
  emailAddresses: string[]
  ssn: string
  dob: string
}

export interface InstantLinkResult {
  status: number
  description: string
  sessionId: string
  linkClicked: boolean
  ipAddressMatch: boolean
  phoneMatch: string
}

export interface AccountInformation {
  activationCode?: string
  trustedFormCertId?: string
  membershipInvitationKey?: string
  firstName: string
  middleInitial: string
  lastName: string
  email: string
  password: string
  currentStreet: string
  currentCity: string
  currentState: string
  currentZip: string
  previousStreet: string
  previousCity: string
  previousState: string
  previousZip: string
  familyInvitationEmail: string
  isCurrentAddress: boolean
}

export interface AccountInformationReadOnly {
  isFirstNameReadOnly?: boolean
  isLastNameReadOnly?: boolean
  isCurrentStreetReadOnly?: boolean
  isCurrentCityReadOnly?: boolean
  isCurrentStateReadOnly?: boolean
  isCurrentZipReadOnly?: boolean
}

export interface IdentityInformation {
  email?: string
  dateOfBirth: string
  subscribeSMS: boolean
  lastFourSSN?: string
  password?: string
  phone: string
  ssn?: string
  proveSessionId?: string
  isProveBypass?: boolean
  trustScore?: number
}

export interface IdentityInformationReadOnly {
  isDateOfBirthReadOnly?: boolean
  isSsnReadOnly?: boolean
}

export interface BillingInformation {
  ccv: string
  city: string
  creditCardNumber: string
  expiration: string
  isBillingHomeAddress: boolean
  state: string
  street: string
  zip: string
  appliedCoupon?: Coupon | null
  couponCode: string
  subscribeSMS?: boolean
  phone?: string
  kountSessionId?: string
}

export interface EnrollmentInformation {
  memberId: string
  planCode: string
  offerCode: string
}

export interface Question {
  text: string
  answers: {
    text: string
    id: string
  }[]
}

export type AppDispatch = ThunkDispatch<RootState, unknown, any> // eslint-disable-line
export type RootState = ReturnType<typeof rootReducer>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export interface AddOn {
  code: string
  name: string
  price: number
  defaultPrice: number
  itemHighlight?: string
  longDesc: string
  isSelected?: boolean
}

export interface InterstitialAddOn extends AddOn {
  itemHighlight: string
}

export interface PostEnrollmentAddOn extends InterstitialAddOn {
  addonActualPrice: number
  isCheapTrial?: boolean | null
  hasDiscount?: boolean
  headerStatement: string
  itemHighlight: string
  longDescription: string
  name: string
  code: string
  billingIntervalDisplayName: string
  addonDefaultPrice: number
  addonTrialPrice?: number | null
  imageUrl?: string | null
  errors?: string | string[]
}

export interface InvitationPlan {
  couponCode: string
  toEmail: string
}
