import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState, ThunkAPI } from '../types'
import { getVerificationQuestions } from './getVerificationQuestions'
import creditCardType from 'credit-card-type'
import { heap } from '@/utils'
import { BrandName, ErrorCodes, ErrorMessagesMap, SessionStore, TrackingTriggerMapping, WebsiteCode } from '@/constants'
import { loadTrackUrl } from '@/state/tracking'
import * as gtag from '../../utils/gtag'
import { parseQueryStringTrackInfo } from '@/utils'
import { apm } from '@elastic/apm-rum'
import { track } from './track'
import { trackUpdate } from './trackUpdate'
import { isNoAccount } from '@/utils'
import { sha256 } from 'js-sha256'
import { EnrollmentState } from '../enrollment'
import { redirect } from './redirect'

type EnrollmentData = {
  trackUrl?: string
  membershipNumber: string
  cuid: string
}

type EnrollmentError = {
  redirectUrl?: string
  code: string
  message: string
}

type EnrollmentResponse = {
  data: EnrollmentData
  error: EnrollmentError
}

const marketingProgram = process.env.THEME_NAME as string
const isCXIQ = ['CreditScoreIQ', 'CreditBuilderIQ'].includes(marketingProgram)

const handleEnrollmentSuccess = (
  data: EnrollmentData,
  state: RootState,
  enrollment: EnrollmentState,
  dispatch: AppDispatch,
) => {
  const { tracking, plan } = state
  const { account, identity } = enrollment

  if (tracking.trigger === TrackingTriggerMapping.Verify || tracking.trigger === TrackingTriggerMapping.VerifySuccess) {
    const trackUrl = data.trackUrl || null
    dispatch(loadTrackUrl({ trackUrl }))
  }

  const { membershipNumber, cuid } = data

  dispatch(track({ trigger: TrackingTriggerMapping.Verify, membershipId: membershipNumber }))
  dispatch(trackUpdate({ membershipId: membershipNumber }))

  if (plan.details?.isPlanAuthRequired && !identity.isProveBypass) {
    dispatch(
      getVerificationQuestions({
        memberId: membershipNumber,
        offerCode: plan.effortCode ?? '',
        planCode: plan.details?.planCode ?? '',
      }),
    )
  } else {
    dispatch(track({ trigger: TrackingTriggerMapping.VerifySuccess }))
    dispatch(redirect({ membershipNumber, cuid }))
  }

  heap.identify(cuid)

  // eslint-disable-next-line no-console
  console.log(`[HEAP] Identify: `, cuid)

  if (isCXIQ) {
    // eslint-disable-next-line no-console
    console.log(`[HEAP] AddUserProperties: `, {
      [`${marketingProgram} Membership ID`]: membershipNumber,
    })

    heap.addUserProperties({
      [`${marketingProgram} Membership ID`]: membershipNumber,
    })
  }

  gtag.purchaseEvent({
    brand: BrandName,
    offerCode: plan.effortCode,
    plan: plan.details?.planCode,
    totalValue: Number(plan.details?.price || 0),
    transactionId: cuid,
    zip: account.currentZip,
    emailHashed: sha256(account.email),
  })

  sessionStorage?.removeItem(SessionStore.Account)
  sessionStorage?.removeItem(SessionStore.Identity)
  sessionStorage?.removeItem(SessionStore.ProvePrefill)
}

const handleEnrollmentError = (error: EnrollmentError, phoneNumber?: string): EnrollmentError => {
  apm.captureError(new Error(error.message))

  if (error.redirectUrl) {
    window.location.href = error.redirectUrl
  }

  let _error = { ...error }

  switch (error.code) {
    case ErrorCodes.EnrollmentPending:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.EnrollmentPending],
      }
      break
    case ErrorCodes.GamerLogic:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.GamerLogic],
      }
      break
    case ErrorCodes.BlocklistByIPAddress:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.BlocklistByIPAddress] + phoneNumber,
      }
      break
    case ErrorCodes.IncludePrepaidRedirectOn:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.IncludePrepaidRedirectOn],
      }
      break
    case ErrorCodes.InvalidCoupon:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.InvalidCoupon],
      }
      break
    case ErrorCodes.DuplicateMemberBySSN:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.DuplicateMemberBySSN],
      }
      break
    case ErrorCodes.DuplicateMemberByHousehold:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.DuplicateMemberByHousehold],
      }
      break
    case ErrorCodes.InvalidSSN:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.InvalidSSN],
      }
      break
    case ErrorCodes.SuspiciousPaymentDetails:
      _error = {
        ...error,
        message: ErrorMessagesMap[ErrorCodes.SuspiciousPaymentDetails],
      }
      break
  }

  return _error
}

const enrollMemberRequest: AsyncThunkPayloadCreator<EnrollmentData, void, ThunkAPI> = async (
  args: void,
  { rejectWithValue, dispatch, getState },
) => {
  try {
    const state = getState() as RootState
    const { plan, enrollment, tracking, recaptcha, addOns, activation, residentData } = state
    const { account, identity, billing, errorCode } = enrollment
    const reinstatePendingMember =
      errorCode === ErrorCodes.EnrollmentPending || Boolean(plan.details?.isBypassReinstatement)
    const ignoreTrial = errorCode === ErrorCodes.GamerLogic || errorCode === ErrorCodes.IncludePrepaidRedirectOn
    const ccTypeInfo = creditCardType(String(billing.creditCardNumber))

    apm.setUserContext({ email: account?.email })

    apm.setCustomContext({ userAction: 'enrollMemberRequest' })

    try {
      const requestUrl = `${process.env.NEXT_PUBLIC_API_URL}/member-service/v2/gateway/enroll`
      const response = await fetch(requestUrl, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: account.email,
          firstName: account.firstName,
          middleName: account.middleInitial,
          lastName: account.lastName,
          websiteCode: WebsiteCode,
          password: state.enrollment.account.password,
          offerCode: plan.effortCode,
          planCode: plan.details?.planCode,
          source: tracking.fullUrl || '',
          ignoreTrial,
          creditCardNumber: String(billing.creditCardNumber),
          cctype: isNoAccount(plan.details) ? '' : ccTypeInfo[0].type,
          expirationDate: billing.expiration,
          ccv: billing.ccv,
          billing: billing.isBillingHomeAddress
            ? {
                street: account.currentStreet,
                city: account.currentCity,
                state: account.currentState,
                zip: account.currentZip,
              }
            : {
                street: billing.street,
                city: billing.city,
                state: billing.state,
                zip: billing.zip,
              },
          shipping: {
            street: account.currentStreet,
            city: account.currentCity,
            state: account.currentState,
            zip: account.currentZip,
          },
          previous: account.isCurrentAddress
            ? null
            : {
                street: account.previousStreet,
                city: account.previousCity,
                state: account.previousState,
                zip: account.previousZip,
              },
          isPlanAuthRequired: plan.details?.isPlanAuthRequired,
          phone: plan.details?.isPlanAuthRequired
            ? identity.phone.replace(/\D/g, '')
            : billing.phone?.replace(/\D/g, ''),
          dob: identity.dateOfBirth,
          ssn: plan.details?.isPlanAuthRequired ? identity.ssn : '',
          subscribeSMS: plan.details?.isPlanAuthRequired ? identity.subscribeSMS : billing.subscribeSMS,
          trustedFormCertId: account.trustedFormCertId,
          trackInfo: parseQueryStringTrackInfo(tracking.query?.queryString || ''),
          reinstatePendingMember,
          isFree: isNoAccount(plan.details),
          couponCode: billing.couponCode,
          interstitialAddonCodes: addOns.items.filter((item) => item.isSelected).map((i) => i.code),
          velocityByPass: recaptcha.velocityByPass,
          familyInvitationEmail: account.familyInvitationEmail,
          membershipInvitationKey: account.membershipInvitationKey,
          kountSessionId: billing.kountSessionId,
          proveSessionId: identity.proveSessionId,
          isProveBypass: identity.isProveBypass,
          proveTrustScore: identity.trustScore,
          activationCode: account.activationCode,
          membershipNumber: activation?.details?.memberInfo?.membershipNo,
          orderSource: process.env.NEXT_PUBLIC_ORDER_SOURCE,
          externalCustomerId: residentData.details?.residentId ? `OptIn_${residentData.details?.residentId}` : null,
        }),
      })

      const body = (await response.json()) as EnrollmentResponse

      if (body.error) {
        return rejectWithValue(handleEnrollmentError(body.error, plan.details?.phone))
      } else {
        handleEnrollmentSuccess(body.data, state, enrollment, dispatch)

        return body.data
      }
    } catch (error) {
      return rejectWithValue({})
    }
  } catch (e) {
    const error = e as Error

    apm.captureError(error)

    return rejectWithValue(ErrorMessagesMap[ErrorCodes.InternalError])
  }
}

export const enrollMember = createAsyncThunk<EnrollmentData, void, ThunkAPI>('user/enrollMember', enrollMemberRequest)
